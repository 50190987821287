:root {
    /* Default color palette */
    --primary-color: #00597a;
    --secondary-color: #33B4E3;
    --background-color: #ffffff;
    --text-color: #333;
    --hover-color: #003A4C;
    --active-color: #33B4E3;
    --error-background-color: #ffecec;
    --error-text-color: red;
    --error-border-color: red;
    --placeholder-color: #00597a;
    --deal-card-shadow: rgba(0, 0, 0, 0.2);
    --input-background-color: #f9f9f9;
    --input-border-color: #00597a;
    --dropdown-background-color: #00597a;
    --dropdown-text-color: #fafafa;
    --sidebar-background-color: #f0f0f0;
    --sidebar-text-color: #333;
    --original-price-color: #5c5c5c;
    --upvote-color: #0095d5;
    --downvote-color: #a45104;
    --hover-background-color: rgba(105, 207, 251, 0.7);
    --share-dropdown-background-color: #f9f9f9;
    --username-choice-border-color: #ddd;
}

/* Dark mode palette */
.dark-mode {
    --primary-color: #007bff;
    --secondary-color: #80d9f8;
    --background-color: #282c34;
    --text-color: #ffffff;
    --hover-color: #61dafb;
    --active-color: #0d6efd;
    --error-background-color: #441c1c;
    --error-text-color: #ffaaaa;
    --error-border-color: #ffaaaa;
    --placeholder-color: #cccccc;
    --deal-card-shadow: rgba(255, 255, 255, 0.1);
    --input-background-color: #333;
    --input-border-color: #61dafb;
    --dropdown-background-color: #333;
    --dropdown-text-color: #ffffff;
    --sidebar-background-color: #3a3f44;
    --sidebar-text-color: #adb5bd;
    --original-price-color: #949494;
    --upvote-color: #4caf50;
    --downvote-color: #f44336;
    --hover-background-color: rgba(255, 255, 255, 0.2);
    --share-dropdown-background-color: #424242;
    --username-choice-border-color: #6c757d;
}

/* Colorful light mode palette */
.colorful-light-mode {
    --primary-color: #e85a4f;
    /* Warm red */
    --secondary-color: #e98074;
    /* Soft coral */
    --background-color: #f9f9f9;
    /* Light gray */
    --text-color: #383838;
    /* Dark gray */
    --hover-color: #d8c3a5;
    /* Warm beige */
    --active-color: #e98074;
    /* Soft coral, same as secondary for consistency */
    --error-background-color: #ffcccc;
    /* Light red for errors */
    --error-text-color: #e85a4f;
    /* Warm red */
    --error-border-color: #e85a4f;
    /* Warm red */
    --placeholder-color: #a8a8a8;
    /* Gray */
    --deal-card-shadow: rgba(0, 0, 0, 0.1);
    /* Soft shadow */
    --input-background-color: #ffffff;
    /* White */
    --input-border-color: #e85a4f;
    /* Warm red */
    --dropdown-background-color: #e98074;
    /* Soft coral */
    --dropdown-text-color: #ffffff;
    /* White */
    --sidebar-background-color: #f2f2f2;
    /* Very light gray */
    --sidebar-text-color: #383838;
    /* Dark gray */
    --original-price-color: #a8a8a8;
    /* Gray */
    --upvote-color: #20bf6b;
    /* Green */
    --downvote-color: #eb3b5a;
    /* Red */
    --hover-background-color: #d8c3a5;
    /* Warm beige */
    --share-dropdown-background-color: #eaeaea;
    /* Light gray */
    --username-choice-border-color: #e85a4f;
    /* Warm red */
}

.designed {
    /* Modern and Designed Color Palette */
    --primary-color: #2D3142;
    /* Dark Blue-Gray */
    --secondary-color: #EF8354;
    /* Warm Orange */
    --background-color: #F0F3BD;
    /* Soft Yellow */
    --text-color: #2D3142;
    /* Dark Blue-Gray, for readability */
    --hover-color: #4F5D75;
    /* Lighter Blue-Gray */
    --active-color: #BFC0C0;
    /* Light Gray */
    --error-background-color: #FF6B6B;
    /* Bright Red */
    --error-text-color: #FFFFFF;
    /* White */
    --error-border-color: #FF6B6B;
    /* Bright Red */
    --placeholder-color: #BFC0C0;
    /* Light Gray */
    --deal-card-shadow: rgba(45, 49, 66, 0.2);
    /* Shadow for Dark Blue-Gray */
    --input-background-color: #FFFFFF;
    /* White */
    --input-border-color: #2D3142;
    /* Dark Blue-Gray */
    --dropdown-background-color: #4F5D75;
    /* Lighter Blue-Gray */
    --dropdown-text-color: #FFFFFF;
    /* White */
    --sidebar-background-color: #8E9AAF;
    /* Soft Blue */
    --sidebar-text-color: #FFFFFF;
    /* White */
    --original-price-color: #BFC0C0;
    /* Light Gray */
    --upvote-color: #3F7CAC;
    /* Soft Blue */
    --downvote-color: #D7263D;
    /* Red */
    --hover-background-color: #EF8354;
    /* Warm Orange for Hover */
    --share-dropdown-background-color: #8E9AAF;
    /* Soft Blue */
    --username-choice-border-color: #2D3142;
    /* Dark Blue-Gray */
}