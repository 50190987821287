@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@200;400&display=swap);
html {
  font-size: 62.5%;
}

body {
  margin: 0;
  font-family: 'Lato', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root {
    /* Default color palette */
    --primary-color: #00597a;
    --secondary-color: #33B4E3;
    --background-color: #ffffff;
    --text-color: #333;
    --hover-color: #003A4C;
    --active-color: #33B4E3;
    --error-background-color: #ffecec;
    --error-text-color: red;
    --error-border-color: red;
    --placeholder-color: #00597a;
    --deal-card-shadow: rgba(0, 0, 0, 0.2);
    --input-background-color: #f9f9f9;
    --input-border-color: #00597a;
    --dropdown-background-color: #00597a;
    --dropdown-text-color: #fafafa;
    --sidebar-background-color: #f0f0f0;
    --sidebar-text-color: #333;
    --original-price-color: #5c5c5c;
    --upvote-color: #0095d5;
    --downvote-color: #a45104;
    --hover-background-color: rgba(105, 207, 251, 0.7);
    --share-dropdown-background-color: #f9f9f9;
    --username-choice-border-color: #ddd;
}

/* Dark mode palette */
.dark-mode {
    --primary-color: #007bff;
    --secondary-color: #80d9f8;
    --background-color: #282c34;
    --text-color: #ffffff;
    --hover-color: #61dafb;
    --active-color: #0d6efd;
    --error-background-color: #441c1c;
    --error-text-color: #ffaaaa;
    --error-border-color: #ffaaaa;
    --placeholder-color: #cccccc;
    --deal-card-shadow: rgba(255, 255, 255, 0.1);
    --input-background-color: #333;
    --input-border-color: #61dafb;
    --dropdown-background-color: #333;
    --dropdown-text-color: #ffffff;
    --sidebar-background-color: #3a3f44;
    --sidebar-text-color: #adb5bd;
    --original-price-color: #949494;
    --upvote-color: #4caf50;
    --downvote-color: #f44336;
    --hover-background-color: rgba(255, 255, 255, 0.2);
    --share-dropdown-background-color: #424242;
    --username-choice-border-color: #6c757d;
}

/* Colorful light mode palette */
.colorful-light-mode {
    --primary-color: #e85a4f;
    /* Warm red */
    --secondary-color: #e98074;
    /* Soft coral */
    --background-color: #f9f9f9;
    /* Light gray */
    --text-color: #383838;
    /* Dark gray */
    --hover-color: #d8c3a5;
    /* Warm beige */
    --active-color: #e98074;
    /* Soft coral, same as secondary for consistency */
    --error-background-color: #ffcccc;
    /* Light red for errors */
    --error-text-color: #e85a4f;
    /* Warm red */
    --error-border-color: #e85a4f;
    /* Warm red */
    --placeholder-color: #a8a8a8;
    /* Gray */
    --deal-card-shadow: rgba(0, 0, 0, 0.1);
    /* Soft shadow */
    --input-background-color: #ffffff;
    /* White */
    --input-border-color: #e85a4f;
    /* Warm red */
    --dropdown-background-color: #e98074;
    /* Soft coral */
    --dropdown-text-color: #ffffff;
    /* White */
    --sidebar-background-color: #f2f2f2;
    /* Very light gray */
    --sidebar-text-color: #383838;
    /* Dark gray */
    --original-price-color: #a8a8a8;
    /* Gray */
    --upvote-color: #20bf6b;
    /* Green */
    --downvote-color: #eb3b5a;
    /* Red */
    --hover-background-color: #d8c3a5;
    /* Warm beige */
    --share-dropdown-background-color: #eaeaea;
    /* Light gray */
    --username-choice-border-color: #e85a4f;
    /* Warm red */
}

.designed {
    /* Modern and Designed Color Palette */
    --primary-color: #2D3142;
    /* Dark Blue-Gray */
    --secondary-color: #EF8354;
    /* Warm Orange */
    --background-color: #F0F3BD;
    /* Soft Yellow */
    --text-color: #2D3142;
    /* Dark Blue-Gray, for readability */
    --hover-color: #4F5D75;
    /* Lighter Blue-Gray */
    --active-color: #BFC0C0;
    /* Light Gray */
    --error-background-color: #FF6B6B;
    /* Bright Red */
    --error-text-color: #FFFFFF;
    /* White */
    --error-border-color: #FF6B6B;
    /* Bright Red */
    --placeholder-color: #BFC0C0;
    /* Light Gray */
    --deal-card-shadow: rgba(45, 49, 66, 0.2);
    /* Shadow for Dark Blue-Gray */
    --input-background-color: #FFFFFF;
    /* White */
    --input-border-color: #2D3142;
    /* Dark Blue-Gray */
    --dropdown-background-color: #4F5D75;
    /* Lighter Blue-Gray */
    --dropdown-text-color: #FFFFFF;
    /* White */
    --sidebar-background-color: #8E9AAF;
    /* Soft Blue */
    --sidebar-text-color: #FFFFFF;
    /* White */
    --original-price-color: #BFC0C0;
    /* Light Gray */
    --upvote-color: #3F7CAC;
    /* Soft Blue */
    --downvote-color: #D7263D;
    /* Red */
    --hover-background-color: #EF8354;
    /* Warm Orange for Hover */
    --share-dropdown-background-color: #8E9AAF;
    /* Soft Blue */
    --username-choice-border-color: #2D3142;
    /* Dark Blue-Gray */
}
/* import themes.css */

body {
  font-family: 'Poppins', sans-serif;
  background: var(--background-color);
  color: var(--text-color);
}

.app-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: radial-gradient(circle at center, rgb(216 216 216 / 80%), var(--background-color));
}

.add-deal-tab {
  background-color: var(--primary-color);
  border: none;
  border-radius: 10px;
  color: var(--dropdown-text-color);
  cursor: pointer;
  font-size: 0.8em;
  font-weight: bold;
  margin: 5px;
  padding: 10px 20px;
  transition: background-color 0.3s;
}

.add-deal-tab:hover {
  background-color: var(--secondary-color);
}

.add-deal-tab-selected {
  background-color: var(--secondary-color);
  color: var(--text-color);
}

.footer {
  width: 100%;
  height: 10%;
  text-align: center;
}

.main-content-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 70%;
  height: 80%;
  margin-bottom: 1%;
  padding: 0 20px;
}

.main-content {
  flex-grow: 1;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-gap: 5px;
  gap: 5px;
  padding: 5px;
  justify-content: center;
  margin: 0 auto;
  width: 80%;
}

.sidebar {
  width: 25%;
  height: 50vh;
  color: var(--sidebar-text-color);
  /* background-color: var(--sidebar-background-color); */
  font: 1.4em 'Poppins', sans-serif;
  padding: 30px 0px 20px 0px;
  overflow-y: auto;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.sidebar-header {
  background-color: var(--sidebar-background-color);
  border-radius: 5px;
  padding: 20px;
}

.sidebar h3 {
  font-size: 1.2em;
  margin-bottom: 10px;
  background-color: #efefef;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
}

.sidebar h3.active {
  background-color: #ddd;
}

.sidebar ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.sidebar li {
  border-bottom: 1px solid #ddd;
  padding: 5px 0;
}

.sidebar a {
  color: var(--sidebar-text-color);
  text-decoration: none;
  font-weight: bold;
}

.sidebar a.active {
  color: var(--secondary-color);
}

.sidebar a:hover {
  color: var(--secondary-color);
}

.deal-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: calc(100% - 6px);
  max-width: 100%;
  max-height: 350px;
  min-height: 350px;
  overflow: hidden;
  margin: 10px;
  box-shadow: 0 4px 8px 0 var(--deal-card-shadow);
  transition: 0.3s;
  background: var(--background-color);
  border-radius: 10px !important;
  aspect-ratio: 2 / 5;
  position: relative;
  overflow: visible;
}

.deal-card a {
  color: inherit;
  text-decoration: none;
}

.deal-card a:hover,
.deal-card a:visited {
  color: inherit;
  text-decoration: none;
}

.deal-card:hover {
  box-shadow: 0 8px 16px 0 var(--deal-card-shadow);
}

.deal-image-container {
  position: relative;
  width: 100%;
  height: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--background-color);
  overflow: hidden;
  margin-top: 5px;
}

.deal-image {
  display: block;
  height: auto;
  max-width: 90%;
  max-height: 90%;
  object-fit: contain;
}

.deal-card-image-slider {
  display: flex;
  transition: -webkit-transform 0.5s ease-in-out;
  transition: transform 0.5s ease-in-out;
  transition: transform 0.5s ease-in-out, -webkit-transform 0.5s ease-in-out;
  width: 100%;
  height: 100%;
}

.deal-slider-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: inherit;
  flex-shrink: 0;
  padding-top: 10px;
}

.left-arrow,
.right-arrow {
  position: absolute;
  top: 30%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  background-color: transparent;
  color: white;
  border: none;
  /* padding: 10px; */
  cursor: pointer;
}

.left-arrow {
  left: 0px;
}

.right-arrow {
  right: 0px;
}

.left-arrow:hover,
.right-arrow:hover {
  background-color: var(--hover-background-color);
  border-radius: 20%;
}

.deal-info {
  padding: 10px;
  /* padding-bottom: 60px; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

.deal-name {
  color: var(--text-color);
  font-size: 1.5em;
  font-weight: 400;
  margin-bottom: 0.5%;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.deal-merchant {
  font-size: 1.2em;
  font-weight: normal;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  margin-top: 2px;
}

.price-container {
  display: flex;
  align-items: baseline;
  justify-content: flex-start;
  width: 100%;
  margin-top: 5px;
}

.price-container .deal-price {
  color: var(--secondary-color);
  font-size: 1.6em;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 3px 10px 0 0;
}

.original-price {
  font-size: 1.3em;
  text-decoration: line-through;
  color: var(--original-price-color);
  margin-right: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.vote-share-section {
  bottom: 10px;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 0;
  border-top: 1px solid #ddd;
  width: 100%;
}

.share-button {
  border: none;
  background: none;
}

.share-button button {
  padding: 5px 10px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.share-button svg {
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.share-button button:hover {
  background-color: var(--primary-color);
  color: var(--dropdown-text-color);
}

.share-dropdown-content {
  position: absolute;
  /* bottom: 90%; */
  background-color: var(--share-dropdown-background-color);
  box-shadow: 0px 8px 16px 0px var(--deal-card-shadow);
  width: -webkit-max-content;
  width: max-content;
  z-index: 100;
  display: flex;
  opacity: 1;
  visibility: visible;
  flex-direction: row;
  /* left: 1%; */
}

.share-dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: center;
}

.share-dropdown-content a:hover {
  background-color: #ddd;
}

.share-dropdown-content img {
  width: 20px;
  height: 20px;
}

.vote-count {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 1.5em;
}

.upvote {
  color: var(--upvote-color);
}

.downvote {
  color: var(--downvote-color);
}

.upvote:hover {
  color: var(--secondary-color);
}

.downvote:hover {
  color: #f60505;
}

.vote-count span {
  margin-left: 10px;
}

.upvote {
  margin-left: 10px;
}

.downvote {
  margin-right: 10px;
}

.price-indicator {
  height: 5px;
  width: 100%;
}

.merchant-filter {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 70%;
  margin-bottom: 1%;
  margin-top: 1%;
}

.merchant-filter button {
  background-color: var(--primary-color);
  border: none;
  color: var(--dropdown-text-color);
  cursor: pointer;
  font-size: 1.5em;
  font-weight: bold;
  margin: 5px;
  padding: 10px 20px;
  border-radius: 20px;
  transition: background-color 0.3s, -webkit-transform 0.3s;
  transition: background-color 0.3s, transform 0.3s;
  transition: background-color 0.3s, transform 0.3s, -webkit-transform 0.3s;
}

.merchant-filter button:hover {
  background-color: var(--hover-color);
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}

.merchant-filter button.selected {
  background-color: var(--secondary-color);
  color: #fff;
  cursor: text;
}


.error-message {
  color: var(--error-text-color);
  background-color: var(--error-background-color);
  border: 1px solid var(--error-border-color);
  padding: 10px;
  margin: 10px 0;
  border-radius: 4px;
  font-size: 0.9em;
}

.username-choice {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border: 2px solid var(--username-choice-border-color);
  border-radius: 5px;
  padding: 20px;
  margin-bottom: 20px;
}

.username-choice p {
  font-size: 1.6em;
  margin: 0;
  flex-grow: 1;
  text-align: center;
}

.username-choice button:first-of-type {
  margin-right: 20px;
}

.username-choice button {
  background-color: var(--primary-color);
  color: var(--dropdown-text-color);
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  margin: 5px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s;
  display: inline-block;
  margin-right: 10px;
}

.username-choice button:hover {
  background-color: var(--hover-color);
}

.username-choice form {
  display: flex;
  align-items: center;
  width: 100%;
  flex-direction: row;
  justify-content: center;
}

.username-choice input[name="username"] {
  padding: 10px;
  margin: 10px 0;
  border: 1px solid var(--input-border-color);
  border-radius: 5px;
  width: 40%;
  margin-right: 10px;
}

.username-choice form button[type="submit"] {
  padding: 10px 15px;
}

.username-choice input::-webkit-input-placeholder {
  color: var(--placeholder-color);
  opacity: 0.7;
}

.username-choice input::placeholder {
  color: var(--placeholder-color);
  opacity: 0.7;
}

@media (max-width: 1000px) {
  .app-container {
    width: 100%;
  }

  .main-content-container {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .main-content {
    max-width: 100%;
  }

  .sidebar {
    width: 80%;
    margin-left: 0;
    margin-top: 20px;
    box-shadow: none;
    height: auto;
  }

  .add-deal-page-add-deal-form {
    width: 95%;
    padding: 10px;
    margin: auto;
  }
}
/* AddDeal.css */
.deal-page-container {
    display: flex;
    align-items: flex-start;
    /* Aligns items to the start of the container */
    grid-gap: 20px;
    gap: 20px;
    /* Space between elements */
}

.add-deal-page-add-deal-container {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 20px;
    gap: 20px;
    padding: 20px;
    width: 70%;
    margin: auto;
    background: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    overflow: auto;
}

.add-deal-page-inputs-container {
    display: flex;
    flex-direction: column;
    grid-gap: 20px;
    gap: 20px;
}

.add-deal-page-progress-bar {
    height: 5px;
    background-color: #2473f3;
    -webkit-animation: loadingAnimation 2s linear infinite;
            animation: loadingAnimation 2s linear infinite;
}

@-webkit-keyframes loadingAnimation {
    0% {
        -webkit-transform: translateX(-100%);
                transform: translateX(-100%);
    }

    100% {
        -webkit-transform: translateX(100%);
                transform: translateX(100%);
    }
}

@keyframes loadingAnimation {
    0% {
        -webkit-transform: translateX(-100%);
                transform: translateX(-100%);
    }

    100% {
        -webkit-transform: translateX(100%);
                transform: translateX(100%);
    }
}

.add-deal-page-add-deal-form {
    display: flex;
    flex-direction: column;
    grid-gap: 20px;
    gap: 20px;
}

.add-deal-page-add-deal-form input,
.add-deal-page-add-deal-form textarea {
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
    font-size: 1.4em;
    background-color: #fff;
}

.add-deal-page-add-deal-form button {
    background-color: #056baf;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1.4em;
    font-weight: 600;
    transition: background-color 0.3s;
}



.add-deal-page-input-group {
    display: flex;
    flex-direction: column;
    grid-gap: 10px;
    gap: 10px;
}

.add-deal-page-editor-controls {
    display: flex;
    grid-gap: 10px;
    gap: 10px;
    border: 1px solid #e3e3e3;
    padding: 5px;
    border-radius: 5px;
    background-color: #f7f7f7;
    flex-wrap: wrap;
}

.add-deal-page-editor-controls button {
    border: none;
    cursor: pointer;
    font-size: 1em;
    transition: color 0.3s;
    background-color: #9d9898;
    color: #333;
}

.add-deal-page-editor-controls button:hover {
    background-color: #333 !important;
    color: #9d9898 !important;
}

.add-deal-page-add-deal-form button:hover {
    background-color: #012d7e;
}

.add-deal-page-add-deal-form .add-deal-page-editor {
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
    background-color: #fff;
    cursor: text;
    min-height: 150px;
}

.add-deal-page-deal-image {
    width: auto;
    /* or you can set a specific width */
    max-width: 20%;
    /* or whatever maximum width you prefer */
    height: auto;
    /* to maintain aspect ratio */
    margin-top: 20px;
    border-radius: 5px;
}


@media (max-width: 1112px) {
    .add-deal-page-add-deal-container {
        width: 95%;
        margin-top: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0px;
        /* Ensure alignment is centered for all child elements */
    }

    .add-deal-page-inputs-container {
        width: 100%;
    }

    .add-deal-page-deal-image {
        order: -1;
    }
}

.add-deal-page-error-message {
    color: #f60505;
    font-size: 1.4em;
    margin-bottom: 10px;
}

.add-deal-page-add-deal-form .add-deal-page-price-input-group {
    display: flex;
    grid-gap: 10px;
    gap: 10px;
    flex-wrap: wrap;
}

.add-deal-page-add-deal-form .add-deal-page-price-input-group input {
    font-size: 1.4em;
    flex: 1 1;
}

.add-deal-page-input-group label {
    font-size: 1.4em;
    font-weight: bold;
    color: #333;
}

.add-deal-page-price-input-group label {
    font-size: 1.4em;
    font-weight: bold;
    color: #333;
    padding: 10px;
}

.add-deal-page-add-deal-button {
    align-self: center;
    /* Aligns the button itself to the center */
    /* Other button styles */
}

.add-deal-page-editor-container {
    min-height: 100px;
    font-size: 1.4em;
}
.faq-container {
  width: 60%;
  /* max-width: 800px; */
  margin: 0 auto;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 10%;

}
.faq-container h2 {
  text-align: center;
  margin-bottom: 20px;
  font-size: 1.8em;
}

.faq-container h3 {
  font-size: 1.6em;
}
.faqs {
  list-style-type: none;
  padding: 0;
  font-size: 1.5em;
}

.faq {
  border-bottom: 1px solid #ccc;
  padding: 10px;
  cursor: pointer;
}

.faq:last-child {
  border-bottom: none;
}
.faq-question {
  color: #0056b3;
}

.faq-answer {
  display: none;
  padding-top: 10px;
}

.faq.active .faq-answer {
  display: block;
}
@media screen and (max-width: 1112px) {
  .faq-container {
    width: 95%;
    padding: 10px;
    margin: auto;
  }
}
/* Header Styles */
.logo img {
    width: 25px; /* Make the image responsive */
    height: auto; /* Maintain aspect ratio */
  }
  
  .breadcrumb ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center; /* Align items vertically */
  }
  
  .breadcrumb li {
    padding: 0 10px; /* Adjust based on your design */
  }
  
  /* Optional: Adjust this to ensure the logo and breadcrumb align perfectly */
  .logo, .breadcrumb {
    display: flex;
    justify-content: center; /* Center children horizontally */
    align-items: center; /* Align children vertically */
  }
  
  
  .header {
      display: flex;
      align-items: center;
      /* justify-content: space-between; */
      padding: 20px 20px;
      font-size: 1.6em;
    }
    
    .logo {
      padding-right: 5em;
      padding-top: 0.4em;
      max-width: 100%;
      /* width: 3%; */
    }
    
    .user-welcome-text{
      color:var(--primary-color); /* New link color */
    }
    .user-welcome-text:hover, .user-welcome-active:hover{
      color: var(--secondary-color); /* Hover color */
    }
    .user-welcome-active{
      color: #985050;
    }
    .breadcrumb ul {
      list-style: none;
      display: flex;
      font-weight: bold;
    }
    
    .breadcrumb ul li {
      margin-right: 20px;
    }
    
    .breadcrumb ul li a.active {
      color: #985050; /* Active link color, adjust as needed */
    }
    
    .breadcrumb ul li a {
      color:var(--primary-color); /* New link color */
      text-decoration: none;
    }
    
    .breadcrumb ul li a:hover {
      color: var(--secondary-color); /* Hover color */
    }
    
  .search-bar {
    display: flex; /* Use flexbox for search bar */
    flex-grow: 1; /* Allow search bar to take up remaining space */
    justify-content: flex-end; 
  }
  
  .search-bar input {
    flex-grow: 1; /* Allow input to take up available space */
    max-width: 200px; /* Set a max width for the input field */
    width: 150px;
    background-color: #f9f9f9;
    border: 1px solid #00597a;
    border-radius: 10px;
    color: #00597a;
    font-size: 0.7em;
    font-weight: bold;
    margin: 5px;
    padding: 10px 20px;
    transition: background-color 0.3s;
  }
  
  .search-bar input::-webkit-input-placeholder {
    font-weight: bold;
    opacity: 0.8;
    color: #0056b3;
  }
  
  .search-bar input::placeholder {
    font-weight: bold;
    opacity: 0.8;
    color: #0056b3;
  }
  
    
    .submenu {
      display: none;
      position: absolute;
      background-color: #f9f9f9;
      min-width: 160px;
      box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
      z-index: 1;
    }
    
    .submenu li a {
      color: black;
      padding: 12px 16px;
      text-decoration: none;
      display: block;
    }
    
    .submenu li a:hover {
      background-color: #f1f1f1;
    }
    .orb-style {
        border: none;
        border-radius: 60%;
        cursor: pointer;
        width: 15px;
        height: 15px;
        margin: 5px;
        display: inline-block;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
      }

    
    @media screen and (max-width: 1112px) {
      .header {
        flex-direction: column;
        padding: 10px;
      }
    .theme-orbs-container{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        grid-gap: 4px;
        gap: 4px;
    }
      .logo {
        padding-right: 0;
        width: 100%; /* Adjust based on your preference */
        display: flex;
        justify-content: center;
      }
    
      .logo img {
        width: 25px; /* Adjust based on your preference */
      }
      .breadcrumb ul {
        flex-direction: row; /* Change to row for a horizontal layout */
        flex-wrap: wrap; /* Allow items to wrap */
        justify-content: center; /* Center items horizontally */
      }
    
      .breadcrumb ul li {
        margin: 5px; /* Adjust spacing */
        flex: 1 0 21%; /* Adjust basis and grow as needed, modify the percentage based on preference */
        text-align: center; /* Center text within each link */
      }
    
      .search-bar input {
        width: 90%; /* Adjust input width for smaller screens */
      }
    }
    
/* import themes.css */

.dashboard-container {
    width: 70%;
    margin: 20px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: var(--background-color);
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 var(--deal-card-shadow);
}

.dashboard-user-info {
    background-color: var(--input-background-color);
    border-radius: 10px;
    padding-left: 2%;
    margin: 2%;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 90%;
}

.dashboard-user-info p {
    font-style: italic;
    font-weight: bold;
    font-size: 1.8em;
    color: var(--primary-color);
    margin: 10px 0;
}

.dashboard-user-info span {
    margin-left: 10px;
    font-size: 1.2em;
    font-weight: bold;
    color: var(--text-color);
    font-style: normal;
}   

.dashboard-header {
    font-size: 2em;
    color: var(--primary-color);
    margin-bottom: 20px;
    padding-top: 3%;
}

.dashboard-table {
    width: 90%;
    border-collapse: collapse;
    margin: 20px 0;
    font: 1.4em 'Poppins', sans-serif;
}

.dashboard-table th,
.dashboard-table td {
    text-align: left;
    padding: 12px;
    border-bottom: 1px solid #ddd;
}

.dashboard-table td a {
    color: var(--primary-color);
    text-decoration: none;
}

.dashboard-table td a:hover {
    text-decoration: underline;
}

.dashboard-table th {
    background-color: var(--primary-color);
    color: var(--dropdown-text-color);
}

.dashboard-table tr:nth-child(even) {
    background-color: var(--input-background-color);
}

.dashboard-table td img {
    width: 20%;
    height: auto;
    border-radius: 5px;
}


@media screen and (max-width: 1112px) {
    .dashboard-container {
        width: 95%;
        padding: 10px;
        margin: auto;
    }

    .dashboard-header {
        font-size: 1.5em;
        /* Adjust header font size for smaller screens */
        text-align: center;
        /* Center the header text */
        padding: 10px 0;
        /* Adjust padding to reduce space */
    }

    .dashboard-user-info {
        width: 100%;
        align-items: center;
        /* Align items in the center for smaller screens */
        text-align: center;
        /* Center text for user info */
    }

    .dashboard-user-info p {
        font-size: 1.2em;
        /* Adjust font size for smaller screens */
    }

    .dashboard-table {
        font-size: 0.8em;
        /* Reduce table font size for readability */
    }
    .dashboard-table th, .dashboard-table td{
        padding: 0px;
    }
    .dashboard-table td img {
        width: 50px;
        /* Adjust image width in table cells */
        height: auto;
        /* Keep aspect ratio */
    }

    .tab-buttons {
        flex-direction: column;
        /* Stack tab buttons vertically on small screens */
    }
}
.tab {
    margin-top: 20px;
    font-size: 1.2em;
    font-family: 'Poppins', sans-serif;
    width: 100%;}

.tab-buttons {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
}

.tab-button {
    background-color: var(--input-background-color);
    border: 1px solid #ddd;
    padding: 10px 20px;
    font-weight: bold;
    cursor: pointer;
    margin-right: 5px;
    border-radius: 5px;
    color: var(--primary-color);
    font-size: 1.2em;
    font-family: 'Poppins', sans-serif;

}

.tab-button.active {
    background-color: var(--primary-color);
    color: var(--dropdown-text-color);
}

.tab-tab-content {
    background-color: var(--input-background-color);
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 4px 6px var(--deal-card-shadow);
}

.pending-deals-container,
.active-deals-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.pending-deal,
.active-deal {
    background-color: var(--background-color);
    padding: 15px;
    margin-bottom: 10px;
    width: 90%;
    box-shadow: 0px 4px 8px var(--deal-card-shadow);
    border-radius: 10px;
}

.tab-deal-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px;
    border-bottom: 1px solid #ddd;
    margin-bottom: 10px;
}

.deal-info>div {
    margin-bottom: 5px;
}

.deal-title,
.deal-store,
.deal-price {
    font-size: 1em;
    color: var(--text-color);
}

.deal-image img {
    max-width: 100px;
    height: auto;
    border-radius: 5px;
    margin-top: 10px;
    cursor: pointer;
}

.deal-actions button {
    padding: 10px 20px;
    margin-right: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-weight: bold;
    background-color: var(--primary-color);
    color: var(--dropdown-text-color);
}

.deal-actions button:hover {
    opacity: 0.8;
}

.tab-error-message {
    color: var(--error-text-color);
    margin-top: 10px;
}

.deal-updated {
    background-color: #e0f7fa;
}

.tab-deal-actions-reject {
    background-color: #f4b352;
    color: var(--dropdown-text-color);
}

.tab-deal-actions-reject:hover {
    background-color: #e7900e;
    opacity: 0.8;
}

.tab-deal-actions-approve {
    background-color: #4caf50;
    color: var(--dropdown-text-color);
}

.tab-deal-actions-approve:hover {
    background-color: #3e8e41;
    opacity: 0.8;
}

@media screen and (max-width: 768px) {

    .pending-deals-container .pending-deal,
    .active-deals-container .active-deal {
        width: 100%;
    }
}
