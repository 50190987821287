/* AddDeal.css */
.deal-page-container {
    display: flex;
    align-items: flex-start;
    /* Aligns items to the start of the container */
    gap: 20px;
    /* Space between elements */
}

.add-deal-page-add-deal-container {
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
    padding: 20px;
    width: 70%;
    margin: auto;
    background: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    overflow: auto;
}

.add-deal-page-inputs-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.add-deal-page-progress-bar {
    height: 5px;
    background-color: #2473f3;
    animation: loadingAnimation 2s linear infinite;
}

@keyframes loadingAnimation {
    0% {
        transform: translateX(-100%);
    }

    100% {
        transform: translateX(100%);
    }
}

.add-deal-page-add-deal-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.add-deal-page-add-deal-form input,
.add-deal-page-add-deal-form textarea {
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
    font-size: 1.4em;
    background-color: #fff;
}

.add-deal-page-add-deal-form button {
    background-color: #056baf;
    width: fit-content;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1.4em;
    font-weight: 600;
    transition: background-color 0.3s;
}



.add-deal-page-input-group {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.add-deal-page-editor-controls {
    display: flex;
    gap: 10px;
    border: 1px solid #e3e3e3;
    padding: 5px;
    border-radius: 5px;
    background-color: #f7f7f7;
    flex-wrap: wrap;
}

.add-deal-page-editor-controls button {
    border: none;
    cursor: pointer;
    font-size: 1em;
    transition: color 0.3s;
    background-color: #9d9898;
    color: #333;
}

.add-deal-page-editor-controls button:hover {
    background-color: #333 !important;
    color: #9d9898 !important;
}

.add-deal-page-add-deal-form button:hover {
    background-color: #012d7e;
}

.add-deal-page-add-deal-form .add-deal-page-editor {
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
    background-color: #fff;
    cursor: text;
    min-height: 150px;
}

.add-deal-page-deal-image {
    width: auto;
    /* or you can set a specific width */
    max-width: 20%;
    /* or whatever maximum width you prefer */
    height: auto;
    /* to maintain aspect ratio */
    margin-top: 20px;
    border-radius: 5px;
}


@media (max-width: 1112px) {
    .add-deal-page-add-deal-container {
        width: 95%;
        margin-top: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0px;
        /* Ensure alignment is centered for all child elements */
    }

    .add-deal-page-inputs-container {
        width: 100%;
    }

    .add-deal-page-deal-image {
        order: -1;
    }
}

.add-deal-page-error-message {
    color: #f60505;
    font-size: 1.4em;
    margin-bottom: 10px;
}

.add-deal-page-add-deal-form .add-deal-page-price-input-group {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
}

.add-deal-page-add-deal-form .add-deal-page-price-input-group input {
    font-size: 1.4em;
    flex: 1;
}

.add-deal-page-input-group label {
    font-size: 1.4em;
    font-weight: bold;
    color: #333;
}

.add-deal-page-price-input-group label {
    font-size: 1.4em;
    font-weight: bold;
    color: #333;
    padding: 10px;
}

.add-deal-page-add-deal-button {
    align-self: center;
    /* Aligns the button itself to the center */
    /* Other button styles */
}

.add-deal-page-editor-container {
    min-height: 100px;
    font-size: 1.4em;
}