/* Header Styles */

@import url('../App/themes.css');
.logo img {
    width: 25px; /* Make the image responsive */
    height: auto; /* Maintain aspect ratio */
  }
  
  .breadcrumb ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center; /* Align items vertically */
  }
  
  .breadcrumb li {
    padding: 0 10px; /* Adjust based on your design */
  }
  
  /* Optional: Adjust this to ensure the logo and breadcrumb align perfectly */
  .logo, .breadcrumb {
    display: flex;
    justify-content: center; /* Center children horizontally */
    align-items: center; /* Align children vertically */
  }
  
  
  .header {
      display: flex;
      align-items: center;
      /* justify-content: space-between; */
      padding: 20px 20px;
      font-size: 1.6em;
    }
    
    .logo {
      padding-right: 5em;
      padding-top: 0.4em;
      max-width: 100%;
      /* width: 3%; */
    }
    
    .user-welcome-text{
      color:var(--primary-color); /* New link color */
    }
    .user-welcome-text:hover, .user-welcome-active:hover{
      color: var(--secondary-color); /* Hover color */
    }
    .user-welcome-active{
      color: #985050;
    }
    .breadcrumb ul {
      list-style: none;
      display: flex;
      font-weight: bold;
    }
    
    .breadcrumb ul li {
      margin-right: 20px;
    }
    
    .breadcrumb ul li a.active {
      color: #985050; /* Active link color, adjust as needed */
    }
    
    .breadcrumb ul li a {
      color:var(--primary-color); /* New link color */
      text-decoration: none;
    }
    
    .breadcrumb ul li a:hover {
      color: var(--secondary-color); /* Hover color */
    }
    
  .search-bar {
    display: flex; /* Use flexbox for search bar */
    flex-grow: 1; /* Allow search bar to take up remaining space */
    justify-content: flex-end; 
  }
  
  .search-bar input {
    flex-grow: 1; /* Allow input to take up available space */
    max-width: 200px; /* Set a max width for the input field */
    width: 150px;
    background-color: #f9f9f9;
    border: 1px solid #00597a;
    border-radius: 10px;
    color: #00597a;
    font-size: 0.7em;
    font-weight: bold;
    margin: 5px;
    padding: 10px 20px;
    transition: background-color 0.3s;
  }
  
  .search-bar input::placeholder {
    font-weight: bold;
    opacity: 0.8;
    color: #0056b3;
  }
  
    
    .submenu {
      display: none;
      position: absolute;
      background-color: #f9f9f9;
      min-width: 160px;
      box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
      z-index: 1;
    }
    
    .submenu li a {
      color: black;
      padding: 12px 16px;
      text-decoration: none;
      display: block;
    }
    
    .submenu li a:hover {
      background-color: #f1f1f1;
    }
    .orb-style {
        border: none;
        border-radius: 60%;
        cursor: pointer;
        width: 15px;
        height: 15px;
        margin: 5px;
        display: inline-block;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
      }

    
    @media screen and (max-width: 1112px) {
      .header {
        flex-direction: column;
        padding: 10px;
      }
    .theme-orbs-container{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 4px;
    }
      .logo {
        padding-right: 0;
        width: 100%; /* Adjust based on your preference */
        display: flex;
        justify-content: center;
      }
    
      .logo img {
        width: 25px; /* Adjust based on your preference */
      }
      .breadcrumb ul {
        flex-direction: row; /* Change to row for a horizontal layout */
        flex-wrap: wrap; /* Allow items to wrap */
        justify-content: center; /* Center items horizontally */
      }
    
      .breadcrumb ul li {
        margin: 5px; /* Adjust spacing */
        flex: 1 0 21%; /* Adjust basis and grow as needed, modify the percentage based on preference */
        text-align: center; /* Center text within each link */
      }
    
      .search-bar input {
        width: 90%; /* Adjust input width for smaller screens */
      }
    }
    