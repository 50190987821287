.tab {
    margin-top: 20px;
    font-size: 1.2em;
    font-family: 'Poppins', sans-serif;
    width: 100%;}

.tab-buttons {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
}

.tab-button {
    background-color: var(--input-background-color);
    border: 1px solid #ddd;
    padding: 10px 20px;
    font-weight: bold;
    cursor: pointer;
    margin-right: 5px;
    border-radius: 5px;
    color: var(--primary-color);
    font-size: 1.2em;
    font-family: 'Poppins', sans-serif;

}

.tab-button.active {
    background-color: var(--primary-color);
    color: var(--dropdown-text-color);
}

.tab-tab-content {
    background-color: var(--input-background-color);
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 4px 6px var(--deal-card-shadow);
}

.pending-deals-container,
.active-deals-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.pending-deal,
.active-deal {
    background-color: var(--background-color);
    padding: 15px;
    margin-bottom: 10px;
    width: 90%;
    box-shadow: 0px 4px 8px var(--deal-card-shadow);
    border-radius: 10px;
}

.tab-deal-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px;
    border-bottom: 1px solid #ddd;
    margin-bottom: 10px;
}

.deal-info>div {
    margin-bottom: 5px;
}

.deal-title,
.deal-store,
.deal-price {
    font-size: 1em;
    color: var(--text-color);
}

.deal-image img {
    max-width: 100px;
    height: auto;
    border-radius: 5px;
    margin-top: 10px;
    cursor: pointer;
}

.deal-actions button {
    padding: 10px 20px;
    margin-right: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-weight: bold;
    background-color: var(--primary-color);
    color: var(--dropdown-text-color);
}

.deal-actions button:hover {
    opacity: 0.8;
}

.tab-error-message {
    color: var(--error-text-color);
    margin-top: 10px;
}

.deal-updated {
    background-color: #e0f7fa;
}

.tab-deal-actions-reject {
    background-color: #f4b352;
    color: var(--dropdown-text-color);
}

.tab-deal-actions-reject:hover {
    background-color: #e7900e;
    opacity: 0.8;
}

.tab-deal-actions-approve {
    background-color: #4caf50;
    color: var(--dropdown-text-color);
}

.tab-deal-actions-approve:hover {
    background-color: #3e8e41;
    opacity: 0.8;
}

@media screen and (max-width: 768px) {

    .pending-deals-container .pending-deal,
    .active-deals-container .active-deal {
        width: 100%;
    }
}