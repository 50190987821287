/* import themes.css */
@import url('themes.css');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;400&display=swap');

body {
  font-family: 'Poppins', sans-serif;
  background: var(--background-color);
  color: var(--text-color);
}

.app-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: radial-gradient(circle at center, rgb(216 216 216 / 80%), var(--background-color));
}

.add-deal-tab {
  background-color: var(--primary-color);
  border: none;
  border-radius: 10px;
  color: var(--dropdown-text-color);
  cursor: pointer;
  font-size: 0.8em;
  font-weight: bold;
  margin: 5px;
  padding: 10px 20px;
  transition: background-color 0.3s;
}

.add-deal-tab:hover {
  background-color: var(--secondary-color);
}

.add-deal-tab-selected {
  background-color: var(--secondary-color);
  color: var(--text-color);
}

.footer {
  width: 100%;
  height: 10%;
  text-align: center;
}

.main-content-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 70%;
  height: 80%;
  margin-bottom: 1%;
  padding: 0 20px;
}

.main-content {
  flex-grow: 1;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 5px;
  padding: 5px;
  justify-content: center;
  margin: 0 auto;
  width: 80%;
}

.sidebar {
  width: 25%;
  height: 50vh;
  color: var(--sidebar-text-color);
  /* background-color: var(--sidebar-background-color); */
  font: 1.4em 'Poppins', sans-serif;
  padding: 30px 0px 20px 0px;
  overflow-y: auto;
  position: sticky;
  top: 0;
}

.sidebar-header {
  background-color: var(--sidebar-background-color);
  border-radius: 5px;
  padding: 20px;
}

.sidebar h3 {
  font-size: 1.2em;
  margin-bottom: 10px;
  background-color: #efefef;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
}

.sidebar h3.active {
  background-color: #ddd;
}

.sidebar ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.sidebar li {
  border-bottom: 1px solid #ddd;
  padding: 5px 0;
}

.sidebar a {
  color: var(--sidebar-text-color);
  text-decoration: none;
  font-weight: bold;
}

.sidebar a.active {
  color: var(--secondary-color);
}

.sidebar a:hover {
  color: var(--secondary-color);
}

.deal-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: calc(100% - 6px);
  max-width: 100%;
  max-height: 350px;
  min-height: 350px;
  overflow: hidden;
  margin: 10px;
  box-shadow: 0 4px 8px 0 var(--deal-card-shadow);
  transition: 0.3s;
  background: var(--background-color);
  border-radius: 10px !important;
  aspect-ratio: 2 / 5;
  position: relative;
  overflow: visible;
}

.deal-card a {
  color: inherit;
  text-decoration: none;
}

.deal-card a:hover,
.deal-card a:visited {
  color: inherit;
  text-decoration: none;
}

.deal-card:hover {
  box-shadow: 0 8px 16px 0 var(--deal-card-shadow);
}

.deal-image-container {
  position: relative;
  width: 100%;
  height: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--background-color);
  overflow: hidden;
  margin-top: 5px;
}

.deal-image {
  display: block;
  height: auto;
  max-width: 90%;
  max-height: 90%;
  object-fit: contain;
}

.deal-card-image-slider {
  display: flex;
  transition: transform 0.5s ease-in-out;
  width: 100%;
  height: 100%;
}

.deal-slider-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: inherit;
  flex-shrink: 0;
  padding-top: 10px;
}

.left-arrow,
.right-arrow {
  position: absolute;
  top: 30%;
  transform: translateY(-50%);
  background-color: transparent;
  color: white;
  border: none;
  /* padding: 10px; */
  cursor: pointer;
}

.left-arrow {
  left: 0px;
}

.right-arrow {
  right: 0px;
}

.left-arrow:hover,
.right-arrow:hover {
  background-color: var(--hover-background-color);
  border-radius: 20%;
}

.deal-info {
  padding: 10px;
  /* padding-bottom: 60px; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

.deal-name {
  color: var(--text-color);
  font-size: 1.5em;
  font-weight: 400;
  margin-bottom: 0.5%;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.deal-merchant {
  font-size: 1.2em;
  font-weight: normal;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  margin-top: 2px;
}

.price-container {
  display: flex;
  align-items: baseline;
  justify-content: flex-start;
  width: 100%;
  margin-top: 5px;
}

.price-container .deal-price {
  color: var(--secondary-color);
  font-size: 1.6em;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 3px 10px 0 0;
}

.original-price {
  font-size: 1.3em;
  text-decoration: line-through;
  color: var(--original-price-color);
  margin-right: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.vote-share-section {
  bottom: 10px;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 0;
  border-top: 1px solid #ddd;
  width: 100%;
}

.share-button {
  border: none;
  background: none;
}

.share-button button {
  padding: 5px 10px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.share-button svg {
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.share-button button:hover {
  background-color: var(--primary-color);
  color: var(--dropdown-text-color);
}

.share-dropdown-content {
  position: absolute;
  /* bottom: 90%; */
  background-color: var(--share-dropdown-background-color);
  box-shadow: 0px 8px 16px 0px var(--deal-card-shadow);
  width: max-content;
  z-index: 100;
  display: flex;
  opacity: 1;
  visibility: visible;
  flex-direction: row;
  /* left: 1%; */
}

.share-dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: center;
}

.share-dropdown-content a:hover {
  background-color: #ddd;
}

.share-dropdown-content img {
  width: 20px;
  height: 20px;
}

.vote-count {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 1.5em;
}

.upvote {
  color: var(--upvote-color);
}

.downvote {
  color: var(--downvote-color);
}

.upvote:hover {
  color: var(--secondary-color);
}

.downvote:hover {
  color: #f60505;
}

.vote-count span {
  margin-left: 10px;
}

.upvote {
  margin-left: 10px;
}

.downvote {
  margin-right: 10px;
}

.price-indicator {
  height: 5px;
  width: 100%;
}

.merchant-filter {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 70%;
  margin-bottom: 1%;
  margin-top: 1%;
}

.merchant-filter button {
  background-color: var(--primary-color);
  border: none;
  color: var(--dropdown-text-color);
  cursor: pointer;
  font-size: 1.5em;
  font-weight: bold;
  margin: 5px;
  padding: 10px 20px;
  border-radius: 20px;
  transition: background-color 0.3s, transform 0.3s;
}

.merchant-filter button:hover {
  background-color: var(--hover-color);
  transform: scale(1.05);
}

.merchant-filter button.selected {
  background-color: var(--secondary-color);
  color: #fff;
  cursor: text;
}


.error-message {
  color: var(--error-text-color);
  background-color: var(--error-background-color);
  border: 1px solid var(--error-border-color);
  padding: 10px;
  margin: 10px 0;
  border-radius: 4px;
  font-size: 0.9em;
}

.username-choice {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border: 2px solid var(--username-choice-border-color);
  border-radius: 5px;
  padding: 20px;
  margin-bottom: 20px;
}

.username-choice p {
  font-size: 1.6em;
  margin: 0;
  flex-grow: 1;
  text-align: center;
}

.username-choice button:first-of-type {
  margin-right: 20px;
}

.username-choice button {
  background-color: var(--primary-color);
  color: var(--dropdown-text-color);
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  margin: 5px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s;
  display: inline-block;
  margin-right: 10px;
}

.username-choice button:hover {
  background-color: var(--hover-color);
}

.username-choice form {
  display: flex;
  align-items: center;
  width: 100%;
  flex-direction: row;
  justify-content: center;
}

.username-choice input[name="username"] {
  padding: 10px;
  margin: 10px 0;
  border: 1px solid var(--input-border-color);
  border-radius: 5px;
  width: 40%;
  margin-right: 10px;
}

.username-choice form button[type="submit"] {
  padding: 10px 15px;
}

.username-choice input::placeholder {
  color: var(--placeholder-color);
  opacity: 0.7;
}

@media (max-width: 1000px) {
  .app-container {
    width: 100%;
  }

  .main-content-container {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .main-content {
    max-width: 100%;
  }

  .sidebar {
    width: 80%;
    margin-left: 0;
    margin-top: 20px;
    box-shadow: none;
    height: auto;
  }

  .add-deal-page-add-deal-form {
    width: 95%;
    padding: 10px;
    margin: auto;
  }
}